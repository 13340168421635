import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./user-del.scss";
import newlogo from "../../assets/images/Clever-New-Logo.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../app_url";
import { getPageContent } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";
import viewEye from "../../assets/images/view.png";
import hideEye from "../../assets/images/hide.png";
import InputGroup from "react-bootstrap/InputGroup";
import { NotificationManager } from "react-notifications";

const UserDelete = () => {
  const navigate = useNavigate();
  const { hostLanguage, languageJson } = useLangContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [contentDetails, setContentDetails] = useState();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // get page content
  const getAllPageContent = async () => {
    try {
      let apiRes = await getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if (responseData.res) {
        setContentDetails(responseData.data);
      } else {
        console.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [formDataSignin, setFormDataSignin] = useState({
    email: "",
    password: "",
    lang: hostLanguage,
  });

  const [formErrorsSignin, setFormErrorsSignin] = useState({
    email: "",
    password: "",
  });

  const handleChangeSignin = (e) => {
    const { name, value } = e.target;
    setFormDataSignin({ ...formDataSignin, [name]: value });
    setFormErrorsSignin({ ...formErrorsSignin, [name]: "" });
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    if (validateFormSignin()) {
      setIsSubmitting(true);
      axios
        .post(`${API_BASE_URL}user/login-todelete-account`, JSON.stringify(formDataSignin), {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setIsSubmitting(false);
          if (response.data.res === false) {
            // NotificationManager.error("An error occurred while deleting your account. Please try again.");
            NotificationManager.error("Beim Löschen Ihres Kontos ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.");
          } else {
            // NotificationManager.success("Account deleted successfully.");
            NotificationManager.success("Konto erfolgreich gelöscht.");
            navigate("/Login");
          }
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.error("Error deleting account:", error);
        });
    }
  };

  const validateFormSignin = () => {
    let isValid = true;
    const errors = {
      email: "",
      password: "",
    };

    if (formDataSignin.email === "") {
      errors.email = languageJson?.LoginEmailValidation;
      isValid = false;
    }

    if (formDataSignin.password === "") {
      errors.password = languageJson?.LoginPasswordValidation;
      isValid = false;
    }

    setFormErrorsSignin(errors);
    return isValid;
  };

  useEffect(() => {
    getAllPageContent();
  }, []);

  return (
    <div className="Loginody">
      <div className="MainBody">
        <Link to="/">
          <img src={newlogo} className="MainLogo" alt="logo" />
        </Link>
        <h1>Benutzerkonto löschen</h1>

        <div className="InnerBox">
          <form className="form" onSubmit={handleSignIn}>
            <Form.Group className="mb-4 mail" controlId="">
              <Form.Label>{contentDetails?.signIn_page_data?.emailAddress}</Form.Label>
              <input
                name="email"
                value={formDataSignin.email}
                onChange={handleChangeSignin}
                type="text"
                placeholder={contentDetails?.signIn_page_data?.emailAddress_placeholder}
                className="form-control"
              />
              {formErrorsSignin.email && <span className="error">{formErrorsSignin.email}</span>}
            </Form.Group>

            <Form.Group className="mb-4 lock2" controlId="">
              <Form.Label>{contentDetails?.signIn_page_data?.password}</Form.Label>
              <InputGroup>
                <input
                  name="password"
                  value={formDataSignin.password}
                  onChange={handleChangeSignin}
                  type={showPassword ? "text" : "password"}
                  placeholder={contentDetails?.signIn_page_data?.password_placeholder}
                  className="form-control"
                />
                <Button variant="outline-secondary" className="viewbtn" onClick={togglePasswordVisibility}>
                  {showPassword ? <img src={hideEye} className="viewimg" alt="logo" /> : <img src={viewEye} className="hideimg" alt="logo" />}
                </Button>
              </InputGroup>

              {formErrorsSignin.password && <span className="error">{formErrorsSignin.password}</span>}
            </Form.Group>

            {isSubmitting ? (
              <Button variant="primary" type="submit" className="submitBtn" disabled>
                Löschen
              </Button>
            ) : (
              <Button variant="primary" type="submit" className="submitBtn">
                Löschen
              </Button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserDelete;
