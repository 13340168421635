import React from 'react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Outlet } from 'react-router-dom';
import InnerHeader from '../components/header/InnerHeader';
import Sidebar from '../components/sidebar/Sidebar';

function CommonLayout() {
  return (
    <>
      <div className="lightBlue">
        <InnerHeader />
        <div className="mail-box-main-body">
          <Row className="mail-box-body">
            <Col sm={2}>
              <Sidebar countUpdate={1}/>
            </Col>
            <Outlet/>
          </Row>
        </div>
      </div>
    </>
  )
}

export default CommonLayout