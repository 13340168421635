import React from "react";
import { Link } from "react-router-dom";
import "./Error.scss";

const Error = () => {
  return (
    <div>
      <div className="ErrorBox">
        <span>
          <h5>404</h5>
          <h4>Sorry, we couldn't find this page.</h4>
          <p>But dont worry, you can find plenty of other things on our homepage.</p>
          <Link to="/"> Back to homepage</Link>
        </span>
      </div>
    </div>
  );
};

export default Error;
