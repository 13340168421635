import React, { useState, useEffect, useContext } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import logo from "../../assets/images/LogoW.svg";
// import newlogo from "../../assets/images/Clever-New-Logo.png";
import newWhitelogo from "../../assets/images/WhiteLogo.png";
import "./Header.scss";
import Dropdown from "react-bootstrap/Dropdown";
import { HiOutlineBell } from "react-icons/hi";
// import { GrCart } from "react-icons/gr";
import { FaShoppingCart } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";
import { NotificationManager } from "react-notifications";

import { changeTitle, onLogout } from "../../utils/commonData";
import { getUserDetails, getAllUnreadNotification, getAllReadNotification, getUserStatus, getProductListStep4, getPageContent } from "../../api/apiRequest";
import { useLangContext } from "../../context/languageContext";
import { useContextValues } from "../../context/commonContext";

const InnerHeader = () => {
  const navigate = useNavigate();
  const { hostLanguage, languageJson } = useLangContext();
  const { cartCount, setCartCount } = useContextValues();
  const [unreadNotification, setUnreadNotification] = useState();
  const [notificationData, setNotificationData] = useState();

  const [loginData, setLoginData] = useState();
  const [userDetails, setUserDetails] = useState();
  const [toggle, setToggle] = useState(false);
  const [productId, setProductId] = useState();
  const [step, setStep] = useState();
  const [contentDetails, setContentDetails] = useState();

  // get all unread notifications
  const fetchallUnreadNotification = async () => {
    try {
      let apiRes = await getAllUnreadNotification();
      let responseData = await apiRes.json();
      if (responseData.res) {
        setUnreadNotification(responseData.data);
        setNotificationData(responseData.data);
      } else {
        NotificationManager.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  };

  const getCartCount = async () => {
    try {
      let apiRes = await getProductListStep4(hostLanguage);
      let responseData = await apiRes.json();
      if (responseData.res) {
        if (responseData.totalCount > 0) {
          setCartCount(responseData.totalCount);
        } else {
          setCartCount("0");
        }
      } else {
        setCartCount("0");
        console.error(responseData.msg);
      }
    } catch (error) {
      console.error("Something went wrong!", "", 2000);
    }
  };

  //get all read notifications
  const fetchallReadNotification = async () => {
    try {
      let apiRes = await getAllReadNotification();
      let responseData = await apiRes.json();
      if (responseData.res) {
        setUnreadNotification(responseData.data);
        fetchallUnreadNotification();
      } else {
        NotificationManager.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      // NotificationManager.error('Something went wrong!', '',2000);
    }
  };

  const getUserData = async () => {
    try {
      let apiRes = await getUserDetails();
      let responseData = await apiRes.json();
      if (responseData) {
        console.log(responseData.data);
        setUserDetails(responseData.data);
      } else {
        NotificationManager.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      NotificationManager.error("Something went wrong!", "", 2000);
    }
  };

  // get page content
  const getAllPageContent = async () => {
    try {
      let apiRes = await getPageContent(hostLanguage);
      let responseData = await apiRes.json();
      if (responseData.res) {
        setContentDetails(responseData.data);
        // console.warn(responseData.data.header);
      } else {
        NotificationManager.error(responseData.msg, "", 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const Logout = () => {
    onLogout();
    navigate("/Login");
  };

  const goPage = (page) => {
    navigate(page);
  };

  const initials = userDetails?.full_name
    .split(" ")
    .map((word) => word.charAt(0))
    .join("");

  const handleClick = () => {
    setToggle(!toggle);
    // fetchallReadNotification();
  };

  useEffect(() => {
    let value = JSON.parse(localStorage.getItem("virtualOfficeLoginInfo"));
    console.log(value);
    if (value == undefined) {
      navigate("/Login");
    }
    getUserData();
    fetchallUnreadNotification();
    getCartCount();
  }, []);

  useEffect(() => {
    getAllPageContent();
  }, [hostLanguage]);

  return (
    <div id="Header">
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="">
            <img src={newWhitelogo} className="MainLogo" alt="logo" onClick={() => navigate("/")} style={{ cursor: "pointer" }} />
          </Navbar.Brand>

          <Nav className="">
            <div className="NotiBtn">
              <Nav.Link onClick={handleClick}>
                <HiOutlineBell size="1.5rem" color="#fff" />
                <p className="NotificationNuber">
                  {unreadNotification?.length > 0 && unreadNotification?.length < 10 ? `0${unreadNotification?.length}` : unreadNotification?.length}
                </p>
              </Nav.Link>

              {toggle && notificationData?.length > 0 && (
                <>
                  <ul class="list-group NotiList" style={{ overflowY: "auto", maxHeight: "300px" }}>
                    {notificationData?.map((item, index) => {
                      return <li class="list-group-item">{item?.notification}</li>;
                    })}
                    <li class="list-group-item">
                      <Link onClick={() => fetchallReadNotification()}>Clear All</Link>
                    </li>
                  </ul>
                </>
              )}
            </div>

            <div className="NotiBtn">
              <Nav.Link onClick={() => navigate("/Payment")}>
                <FaShoppingCart size="1.5rem" color="#fff" />
                <p className="NotificationNuber">{cartCount}</p>
              </Nav.Link>
            </div>

            <Dropdown className="HrDropItem">
              <Dropdown.Toggle variant="" id="dropdown-basic">
                <span className="UserImg">{initials}</span>
                {userDetails?.full_name}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => goPage("/Dashboard")}>{contentDetails?.after_login_header?.parent_0}</Dropdown.Item>
               <Dropdown.Item onClick={() => goPage("/CurrentPlan")}>{contentDetails?.after_login_header?.parent_1}</Dropdown.Item>
                <Dropdown.Item onClick={() => goPage("/AccountSettings")}>{contentDetails?.after_login_header?.parent_2}</Dropdown.Item>
                <Dropdown.Item onClick={() => goPage("/LetStarted")}>{contentDetails?.after_login_header?.parent_3}</Dropdown.Item>
                <Dropdown.Item onClick={() => Logout()}>{contentDetails?.after_login_header?.parent_4}</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};

export default InnerHeader;
